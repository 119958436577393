import wretch from "wretch";
import FormUrlAddon from "wretch/addons/formUrl"
import QueryStringAddon from "wretch/addons/queryString";
import type { ArticleNameSearchRequest, ArticleNameSearchResponse, ArticleScheduleOperation, CancelScheduleArticleRequest, RequiredReadingStartDateDeleteRequest, SaveAction, ScheduleArticleRequest, UpdateVersionNoteRequest, VersionDeleteRequest } from "./types";

export const articleNameSearch = (body: ArticleNameSearchRequest): Promise<Array<ArticleNameSearchResponse>> => wretch(
  '/kb/search-article-names').addon(QueryStringAddon).query(body).get().json().then(resp => Object.values(resp))
    
export const versionDelete = (body: VersionDeleteRequest): Promise<boolean> => wretch(
  '/kb/version-quick-delete'
).addon(FormUrlAddon).formUrl({
  id: body.projectId,
  aid: body.articleId,
  vid: body.versionId,
}).post().text().then(resp => resp.trim() === "true");

export const deleteRequiredReadingStartDate = (body: RequiredReadingStartDateDeleteRequest) => wretch(
  '/kb/required-reading-start-date-delete'
).addon(FormUrlAddon).formUrl({
  aid: body.articleId,
  pid: body.projectId,
  sdate: body.startDate
}).post().text().then(resp => resp.trim() === "true");

export const scheduleArticle = (operation: ArticleScheduleOperation, body: ScheduleArticleRequest) => wretch(
  `/kb/schedule-article-${operation}`
).addon(FormUrlAddon).formUrl({
  aid: body.articleId,
  pid: body.projectId,
  date: body.date
}).post().text();

export const cancelScheduledArticle = (operation: ArticleScheduleOperation, body: CancelScheduleArticleRequest) => wretch(
  `/kb/cancel-scheduled-article-${operation}`
).addon(FormUrlAddon).formUrl({
  aid: body.articleId,
  pid: body.projectId,
}).post().text().then(resp => resp.trim() === "true");

export const updateArticleSavePreference = (preference: SaveAction) => wretch(
  '/app/update-user-pref'
).addon(FormUrlAddon).formUrl({
  preference: "article-save-action",
  value: preference,
}).post().text();

export const updateArticleVersionNote = (request: UpdateVersionNoteRequest) => wretch(
  '/kb/update-article-version-note',
).addon(FormUrlAddon).formUrl({
  aid: request.articleId,
  pid: request.projectId,
  vid: request.versionId,
  note: request.note
}).post().text().then(resp => resp.trim() === "true");