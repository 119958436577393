<script lang="ts">
  import CollapsibleSection from "./CollapsibleSection.svelte";
  import { expansionStore } from "./expansionStore";
  import NavItem from "./NavItem.svelte";

  const { toggleSection, openSection } = expansionStore;
  
  export let kbID: string;
  export let activeNav: string;
  export let reportingLinks = [
    {
      'icon': 'fa-chart-bar',
      'name': 'Dashboard',
      'url': '/dashboard/index/id/' + kbID,
      'uid': 'dashboard'
    },
    {
      'icon': 'fa-envelope',
      'name': 'Contact Form',
      'url': '/dashboard/contact-form/id/' + kbID,
      'uid': 'rep-contact-form'
    },
    {
      'icon': 'fa-object-group',
      'name': 'Widget',
      'url': '/dashboard/widget/id/' + kbID,
      'uid': 'rep-widget'
    },
    {
      'icon': 'fa-comment-alt',
      'name': 'Comments',
      'url': '/dashboard/comments/id/' + kbID,
      'uid': 'rep-comments'
    },
    {
      'icon': 'fa-check-to-slot',
      'name': 'Required Reading',
      'url': '/dashboard/required-reading/id/' + kbID,
      'uid': 'rep-required-reading'
    }
  ];

  // make sure section is open current page is in this section
  let activeSectionItem = reportingLinks.find((link, i) => {
    if(link.uid == activeNav)
      openSection("reporting");
  });

</script>
<CollapsibleSection expansionKey="reporting">
  <label
    class="tw-mb-0 tw-text-sm tw-cursor-pointer"
    slot="label"
    for="reporting"
    role="presentation"
    on:click={() => {
      toggleSection("reporting");
    }}>REPORTING</label
  >
  <div slot="open-content">
    {#each reportingLinks as link}
      <NavItem
        {link}
        {activeNav}
      />
    {/each}
  </div>
</CollapsibleSection>