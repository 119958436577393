<script lang="ts">
  import CollapsibleSection from "./CollapsibleSection.svelte";
  import { expansionStore } from "./expansionStore";
  import NavItem from "./NavItem.svelte";

  const { toggleSection, openSection } = expansionStore;
  
  export let kbID: string;
  export let activeNav: string;
  export let toolsLinks = [
    {
      'icon': 'fa-unlink',
      'name': 'Broken Link Checker',
      'url': '/tools/broken-link-checker/id/' + kbID,
      'uid': 'broken-link-checker'
    },
    {
      'icon': 'fa-file-search',
      'name': 'Advanced Search',
      'url': '/tools/find-and-replace/id/' + kbID,
      'uid': 'find-and-replace'
    },
    {
      'icon': 'fa-text',
      'name': 'Customize Text',
      'url': '/tools/multilingual/id/' + kbID,
      'uid': 'default-text-editor'
    }
  ];

  // make sure section is open current page is in this section
  let activeSectionItem = toolsLinks.find((link, i) => {
    if(link.uid == activeNav)
      openSection("tools");
  });

</script>
<CollapsibleSection expansionKey="tools">
  <label
    class="tw-mb-0 tw-text-sm tw-cursor-pointer"
    slot="label"
    for="tools"
    role="presentation"
    on:click={() => {
      toggleSection("tools");
    }}>TOOLS</label
  >
  <div slot="open-content">
    {#each toolsLinks as link}
      <NavItem
        {link}
        {activeNav}
      />
    {/each}
  </div>
</CollapsibleSection>