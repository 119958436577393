<script lang="ts">
  import "./app.css";
  import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
  import Kb from "./lib/KB.svelte";
  import Tools from "./lib/Tools.svelte";
  import Library from "./lib/Library.svelte";
  import Reporting from "./lib/Reporting.svelte";
  import Settings from "./lib/Settings.svelte";
  import type {
    kbData
  } from "./lib/types";
  export let kbID: string;
  export let viewKbUrl: string;
  export let activeNav: string;
  export let isTrial: boolean;
  export let allKbs: kbData[];
  export let curKbName: string;

  const queryClient = new QueryClient();
</script>

<QueryClientProvider client={queryClient}>
  <Kb
    {curKbName}
    {allKbs}
    {kbID}
    {viewKbUrl}
    {activeNav}
    {isTrial}
  />
  <Tools
    {kbID}
    {activeNav}
  />
  <Library
    {kbID}
    {activeNav}
  />
  <Reporting
    {kbID}
    {activeNav}
  />
  <Settings
    {kbID}
    {activeNav}
  />
</QueryClientProvider>