<script lang="ts">
  import * as focusTrap from "focus-trap";
  import { onMount } from "svelte";

  type ModalPlacement = "top" | "bottom" | "middle";

  export let open: boolean;
  export let close: () => void;
  export let id: string;
  export let initialFocus: string = undefined;
  export let placement: ModalPlacement = "middle";

  let modalRef: HTMLElement;
  let modalFocusTrap: focusTrap.FocusTrap;

  const headerProvided = Boolean($$slots.header);

  onMount(() => {
    modalFocusTrap = focusTrap.createFocusTrap(modalRef, {
      clickOutsideDeactivates: true,
      initialFocus: initialFocus,
    });
  });

  function handleClose() {
    if (open) {
      modalFocusTrap.deactivate();
      close();
    }
  }

  function handleWindowKeyDown(event: KeyboardEvent) {
    if (event.key === "Escape") {
      handleClose();
    }
  }

  $: {
    document.body.classList.toggle("no-scroll", open);
  }

  $: if (modalFocusTrap) {
    if (open && !modalFocusTrap.active) {
      modalFocusTrap.activate();
    } else if (modalFocusTrap.active && !open) {
      modalFocusTrap.deactivate();
    }
  }
</script>

<svelte:window on:keydown={handleWindowKeyDown} />
<dialog
  {id}
  class="tw-daisy-modal"
  class:tw-daisy-modal-open={open}
  class:tw-daisy-modal-top={placement === "top"}
  class:tw-daisy-modal-bottom={placement === "bottom"}
  class:tw-daisy-modal-middle={placement === "middle"}
  bind:this={modalRef}
>
  <div class="tw-daisy-modal-box tw-overflow-visible">
    <div
      class="tw-flex tw-justify-between tw-items-center tw-gap-x-4 tw-w-full tw-pb-2 tw-mb-3 tw-border-neutral-content"
      class:tw-justify-between={headerProvided}
      class:tw-justify-end={!headerProvided}
    >
      <slot name="header" />
      <button
        on:click={handleClose}
        type="button"
        class="tw-daisy-btn tw-daisy-btn-sm tw-daisy-btn-circle tw-bg-base-100"
        >✕</button
      >
    </div>
    <slot name="content" />
    <div class="tw-daisy-modal-action">
      <slot name="actions" />
    </div>
  </div>
  <button
    on:click={close}
    type="button"
    class="tw-daisy-modal-backdrop tw-cursor-default"
    tabindex="-1"
  />
</dialog>

<style>
  :global(.no-scroll) {
    overflow: hidden;
  }
</style>
