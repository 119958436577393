<script lang="ts">
  import clsx from "clsx";
  import type { Link } from "./types";
  export let link: Link; 
  export let activeNav: string;
</script>
<div class="tw-flex tw-mt-1.5">
  <a class={clsx(
    "tw-w-full tw-flex tw-justify-start tw-flex-row tw-p-2 tw-rounded tw-text-sm tw-items-center hover:tw-bg-white hover:tw-text-inherit",
    activeNav == link.uid && "tw-bg-white tw-font-bold tw-border tw-text-inherit",
    activeNav != link.uid && "tw-text-ko-base-400"
  )} href={link.url}>
    <span class="tw-flex tw-pr-1.5"><i class="fal fa-fw {link.icon}"></i></span>
    <span class="tw-flex">{link.name}</span>
  </a>
</div>