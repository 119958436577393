<script lang="ts">
  import CollapsibleSection from "./CollapsibleSection.svelte";
  import { expansionStore } from "./expansionStore";
  import NavItem from "./NavItem.svelte";

  const { toggleSection, openSection } = expansionStore;
  
  export let kbID: string;
  export let activeNav: string;
  export let libraryLinks = [
    {
      'icon': 'fa-file',
      'name': 'Files',
      'url': '/library/files/id/' + kbID,
      'uid': 'files'
    },
    {
      'icon': 'fa-tag',
      'name': 'File Labels',
      'url': '/library/labels/id/' + kbID,
      'uid': 'file labels'
    },
    {
      'icon': 'fa-cogs',
      'name': 'Snippets',
      'url': '/library/snippets/id/' + kbID,
      'uid': 'snippets'
    },
    {
      'icon': 'fa-retweet',
      'name': 'Synonyms',
      'url': '/library/synonyms/id/' + kbID,
      'uid': 'synonyms'
    },
    {
      'icon': 'fa-tag',
      'name': 'Tags',
      'url': '/library/tags/id/' + kbID,
      'uid': 'tags'
    }
  ];

  // make sure section is open current page is in this section
  let activeSectionItem = libraryLinks.find((link, i) => {
    if(link.uid == activeNav) {
      openSection("library");
    }
  });
  
</script>
<CollapsibleSection expansionKey="library">
  <label
    class="tw-mb-0 tw-text-sm tw-cursor-pointer"
    slot="label"
    for="library"
    role="presentation"
    on:click={() => {
      toggleSection("library");
    }}>LIBRARY</label
  >
  <div slot="open-content">
    {#each libraryLinks as link}
      <NavItem
        {link}
        {activeNav}
      />
    {/each}
  </div>
</CollapsibleSection>