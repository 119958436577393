<script lang="ts">
  import { createQuery } from "@tanstack/svelte-query";
  import { articleQueries } from "./queries";
  import Autocomplete from "./Autocomplete.svelte";
  import type { ArticleNameSearchResponse } from "./types";

  export let kbID: string;
  export let selectFn: (
    data: ArticleNameSearchResponse[]
  ) => ArticleNameSearchResponse[] = undefined;
  let selectOpen = false;
  let search = "";
  let hideSpinner = false;
  let debouncedSearch = "";
  let timeout: NodeJS.Timeout;

  $: updateSearch = () => {
    debouncedSearch = search;
  };

  $: handleSearch = () => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(updateSearch, 500);
  };

  $: searchEnabled = search.length > 1;

  $: query = createQuery({
    ...articleQueries.search({
      projectId: kbID,
      search: debouncedSearch,
      includeCats: true
    }),
    enabled: searchEnabled,
    select: selectFn,
  });

  $: hasResults =
    $query.isSuccess && !$query.isLoading && $query.data?.length > 0;

  $: showSpinner = !hideSpinner && $query.isLoading && searchEnabled;
</script>

<Autocomplete
  inputId="kb-nav-article-name-search"
  placeholder="Start typing article name.."
  open={hasResults && selectOpen}
  on:input={() => {
    selectOpen = true;
    handleSearch();
  }}
  bind:loading={showSpinner}
  bind:value={search}
>
  {#if $query.data}
    {#each $query.data as article, index (article.id)}
      <li
        tabIndex={index + 1}
        class={index+1 == $query.data.length ? 'tw-w-full' : 'tw-border-b tw-border-b-base-content/10 tw-w-full'}
      >
        {#if article.label.includes('[Category]')}
          <a href={'/kb/category/id/' + kbID + '/cid/' + article.id} title={article.label}>{article.label}</a>
        {:else}
          <a href={'/kb/article/id/' + kbID + '/aid/' + article.id} title={article.label}>{article.label}</a>
        {/if}
      </li>
    {/each}
  {/if}
</Autocomplete>
