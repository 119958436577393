<script lang="ts">
  import NavItem from "./NavItem.svelte";
  import type { ArticleNameSearchResponse, kbData } from "./types";
  import Modal from "./Modal.svelte";
  import ArticleSearch from "./ArticleSearch.svelte";
  import { clickOutside } from "./clickOutside";

  export let allKbs: kbData[];
  export let curKbName: string;
  export let kbID: string;
  export let viewKbUrl: string;
  export let activeNav: string;
  export let isTrial: boolean;

  let articleSearchOpen = false;
  let newRelatedArticleId: string = undefined;
  let newRelatedArticleName: string = undefined;

  const handleSelect = (article: ArticleNameSearchResponse) => {
    newRelatedArticleId = article.id;
    newRelatedArticleName = article.label;
  };

  const close = () => {
    articleSearchOpen = false;
    newRelatedArticleId = undefined;
    newRelatedArticleName = undefined;
  };

  let isDropdownOpen = false // default state (dropdown close)

  const handleDropdownClick = () => {
    isDropdownOpen = !isDropdownOpen // togle state on click
  }

  // don't let the left nav scroll while KB dropdown is open
  $: {
    document.getElementById('app-left-nav').classList.toggle("no-scroll", isDropdownOpen);
  }

  const handleClickOutside = () => {
    // close dropdown when window is clicked elsewhere
    if(isDropdownOpen)
      isDropdownOpen = false;
  }

  export let kbLinks = [
    {
      'icon': 'tw-text-success far fa-list-check',
      'name': 'Get started',
      'url': '/kb/get-started',
      'uid': 'get-started'
    },
    {
      'icon': 'fa-copy',
      'name': 'Articles',
      'url': '/kb/articles/id/' + kbID,
      'uid': 'articles'
    },
    {
      'icon': 'fa-sitemap',
      'name': 'Manage',
      'url': '/kb/manage/id/' + kbID,
      'uid': 'manage'
    },
    {
      'icon': 'fa-book',
      'name': 'Glossary',
      'url': '/kb/glossary/id/' + kbID,
      'uid': 'glossary'
    },
    {
      'icon': 'fa-envelope',
      'name': 'Contact Form',
      'url': '/kb/contact-form/id/' + kbID,
      'uid': 'contact-form'
    },
    {
      'icon': 'fa-desktop',
      'name': 'Homepage',
      'url': '/kb/home-page/id/' + kbID,
      'uid': 'home'
    },
    {
      'icon': 'fa-share-square',
      'name': 'Exports',
      'url': '/kb/pdf-export/id/' + kbID,
      'uid': 'pdf'
    },
    {
      'icon': 'fa-sign-in-alt',
      'name': 'Import',
      'url': '/kb/import/id/' + kbID,
      'uid': 'import'
    }
  ];

</script>
<div class="tw-fixed tw-top-[88px] tw-z-10 tw-max-w-72 tw-max-h-80 tw-overflow-y-auto tw-shadow-2xl tw-border tw-tw-z-10 tw-bg-white tw-px-3.5 tw-py-2 tw-rounded-lg" style:visibility={isDropdownOpen ? 'visible' : 'hidden'}>
  <ul class="tw-text-sm">
    <li class="tw-text-xs tw-uppercase tw-pt-1 tw-pb-2.5 tw-font-semibold"><span class="tw-text-ko-base-400">Knowledge Bases</span></li>
    {#each allKbs as kb, index}
      <li>
        <a 
          class="tw-flex tw-py-1 tw-truncate tw-justify-start tw-items-center {index+1 == allKbs.length ? '' : 'tw-mb-2'}"
          href={'/kb/articles/id/' + kb.id}
          title={kb.name}
        >
          <i class="fal fa-browser"></i>
          <span class="tw-pl-1.5">{kb.name}</span>
        </a>
      </li>
    {/each}
  </ul>
</div>
<div class="tw-border-b tw-mb-2 pt-1.5 tw-z-2 tw-sticky tw-top-0 tw-bg-ko-base-100">
  <div class="tw-flex tw-relative">
    <button 
      type="button" 
      class="tw-w-full tw-text-gray-600 tw-flex tw-justify-between tw-flex-row tw-p-2 tw-rounded tw-text-sm tw-items-center hover:tw-bg-white hover:tw-text-inherit"
      on:click={handleDropdownClick}
      use:clickOutside
      on:outsideclick={handleClickOutside}
    >
      <span class="tw-py-1 tw-inline-block tw-truncate" title={curKbName}>{curKbName}</span>
      <span class="tw-flex"><i class="tw-pr-0 fal fa-fw fa-caret-down"></i></span>
    </button>
  </div>
  <div class="tw-flex tw-mb-2 tw-mt-1.5">
    <button type="button" class="tw-w-full tw-text-gray-600 tw-border tw-mr-1.5 tw-flex tw-justify-center tw-flex-row tw-p-2 tw-rounded tw-text-sm tw-items-center hover:tw-bg-white hover:tw-text-inherit"
    on:click={() => {
      articleSearchOpen = true;
    }}>
      <span class="tw-flex tw-pr-1.5"><i class="fal fa-fw fa-search"></i></span>
      <span class="tw-flex">Search</span>
    </button>
    <a 
      class="tw-w-full tw-border tw-text-gray-600 tw-flex tw-justify-center tw-flex-row tw-p-2 tw-rounded tw-text-sm tw-items-center hover:tw-bg-white hover:tw-text-inherit"
      target="_blank"
      href={viewKbUrl}
    >
      <span class="tw-flex tw-pr-1.5"><i class="fal fa-fw fa-arrow-up-right-from-square"></i></span>
      <span class="tw-flex">View KB</span>
    </a>
  </div>
</div>
{#each kbLinks as link}
  {#if isTrial || link.uid != 'get-started'}
    <NavItem
      {link}
      {activeNav}
    />
  {/if}
{/each}
<Modal
  bind:open={articleSearchOpen}
  {close}
  id="kb-nav-related-article-search"
  initialFocus="#kb-nav-article-name-search"
>
  <h4 slot="header" class="tw-text-lg tw-font-bold tw-text-ko-base-500">
    Search for articles and categories
  </h4>
  <svelte:fragment slot="content">
    <form action={'/kb/manage/id/' + kbID} method="get">
      <input type="hidden" name="filter" value="all" />
      <ArticleSearch {handleSelect} {kbID} />
    </form>
  </svelte:fragment>
</Modal>