import { persisted } from 'svelte-persisted-store'

export type ExpansionStoreKey = "tools" |
  "library" |
  "reporting" |
  "settings";

const initialState: { [x in ExpansionStoreKey]: boolean } = {
  tools: false,
  library: false,
  reporting: false,
  settings: false
}

const createExpansionStore = () => {
  const { subscribe, update, set } = persisted<{[x in ExpansionStoreKey]: boolean}>('nav-expansion-state', initialState);

  return {
    subscribe,
    openSection: (id: ExpansionStoreKey) => update(state => ({
      ...state,
      [id]: true
    })),
    closeSection: (id: ExpansionStoreKey) => update(state => ({
      ...state,
      [id]: false
    })),
    toggleSection: (id: ExpansionStoreKey) => update(state => ({
      ...state,
      [id]: !state[id]
    }))
  }
}

export const expansionStore = createExpansionStore();
