<script lang="ts">
  import { clickOutside } from "./clickOutside";

  export let value = "";
  export let placeholder = "";
  export let open = false;
  export let loading = false;
  export let inputId: string;
</script>

<div
  class="tw-daisy-dropdown tw-group tw-w-full"
  class:tw-daisy-dropdown-open={open}
  use:clickOutside
  on:outsideclick={() => {
    open = undefined;
  }}
>
  <div class="tw-daisy-indicator tw-w-full">
    <input
      name="term"
      type="text"
      autocomplete="off"
      class="tw-daisy-input tw-daisy-input-bordered tw-w-full"
      bind:value
      id={inputId}
      on:input
      on:change
      {placeholder}
      tabIndex={0}
    />
    {#if loading}
      <span
        class="tw-daisy-indicator-item tw-daisy-indicator-middle tw-daisy-loading tw-daisy-loading-spinner"
      />
    {/if}
  </div>
  <div
    class="tw-daisy-dropdown-content tw-w-full tw-bg-base-200 tw-top-14 tw-max-h-72 tw-overflow-auto tw-flex-col tw-rounded-md tw-z-[100]"
    class:group-focus-within:tw-opacity-0={!open}
    class:group-focus-within:tw-invisible={!open}
  >
    <ul class="tw-daisy-menu tw-daisy-menu-compact">
      <slot />
    </ul>
  </div>
</div>
