<script lang="ts">
  import clsx from "clsx";
  import type { ExpansionStoreKey } from "./expansionStore";
  import { expansionStore } from "./expansionStore";
  import { slide } from "svelte/transition";
  import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
  import Fa from "svelte-fa/src/fa.svelte";

  const { toggleSection } = expansionStore;

  export let expansionKey: ExpansionStoreKey;
  export let expansionEnabled = true;
  export let hasBorder = false;

  $: checked = $expansionStore[expansionKey];

  $: arrowClasses = `tw-transition-transform tw-duration-200${
    checked ? " tw-rotate-180" : ""
  }`;
</script>

<div
  class={clsx(
    "tw-rounded-b-none tw-overflow-visible tw-pb-2 tw-mb-2",
    hasBorder && "tw-border-b tw-border-neutral-content"
  )}
>
  <div class="tw-flex tw-justify-between tw-items-center tw-mt-8 tw-ml-2">
    <slot name="label" />
    {#if expansionEnabled}
      <button
        type="button"
        class="tw-daisy-btn tw-daisy-btn-xs tw-bg-base-100 tw-border-base-100"
        on:click={() => {
          toggleSection(expansionKey);
        }}><Fa class={arrowClasses} icon={faChevronDown} size="small" /></button
      >
    {/if}
  </div>
  <slot />
  {#if !checked && expansionEnabled}
    <slot name="closed-content" />
  {/if}
  {#if checked || !expansionEnabled}
    <div transition:slide={{ duration: expansionEnabled ? 400 : 0 }}>
      <slot name="open-content" />
    </div>
  {/if}
</div>
