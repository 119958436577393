import type { QueryFunction } from "@tanstack/svelte-query";
import { articleNameSearch } from "./api";
import type { ArticleNameSearchResponse } from "./types";
import type { ArticleSearchQueryKey, CategorySearchQueryKey } from "./queryTypes";

type CategoryQueries = {
  search: (args: Omit<CategorySearchQueryKey, 'type' | 'scope'>) => {
    queryKey: Array<CategorySearchQueryKey>,
    queryFn: QueryFunction<ArticleNameSearchResponse[], CategorySearchQueryKey[], any>
  }
}

type ArticleQueries = {
  search: (args: Omit<ArticleSearchQueryKey, 'type' | 'scope'>) => {
    queryKey: Array<ArticleSearchQueryKey>,
    queryFn: QueryFunction<ArticleNameSearchResponse[], ArticleSearchQueryKey[], any>
  }
}

export const categoryQueries: CategoryQueries = {
  search: ({ search, categoryId, projectId }: Omit<CategorySearchQueryKey, 'type' | 'scope'>) => ({
    queryKey: [{ type: 'category', scope: 'list', search, categoryId, projectId }],
    queryFn: ({ queryKey }) => articleNameSearch({
      term: queryKey[0].search,
      id: queryKey[0].projectId,
      current: queryKey[0].categoryId,
      cats: true,
      isCat: true
    })
  })
}

export const articleQueries: ArticleQueries = {
  search: ({ search, articleId, projectId, includeCats }: Omit<ArticleSearchQueryKey, 'type' | 'scope'>) => ({
    queryKey: [{ type: 'article', scope: 'list', search, articleId, projectId, includeCats }],
    queryFn: ({ queryKey }) => articleNameSearch({
      term: queryKey[0].search,
      id: queryKey[0].projectId,
      current: queryKey[0].articleId,
      cats: queryKey[0].includeCats,
      isCat: false
    })
  })
}