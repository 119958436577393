<script lang="ts">
  import CollapsibleSection from "./CollapsibleSection.svelte";
  import { expansionStore } from "./expansionStore";
  import NavItem from "./NavItem.svelte";

  const { toggleSection, openSection } = expansionStore;
  
  export let kbID: string;
  export let activeNav: string;
  export let settingsLinks = [
    {
      'icon': 'fa-cog',
      'name': 'Basic',
      'url': '/kb/settings/id/' + kbID,
      'uid': 'basic'
    },
    {
      'icon': 'fa-shield-check',
      'name': 'Security',
      'url': '/kb/security/id/' + kbID,
      'uid': 'security'
    },
    {
      'icon': 'fa-user-lock',
      'name': 'SSO',
      'url': '/kb/sso/id/' + kbID,
      'uid': 'sso'
    },
    {
      'icon': 'fa-search',
      'name': 'Search',
      'url': '/kb/search-settings/id/' + kbID,
      'uid': 'search'
    },
    {
      'icon': 'fa-paint-brush',
      'name': 'Style',
      'url': '/kb/style/id/' + kbID,
      'uid': 'style'
    },
    {
      'icon': 'fa-thumbs-up',
      'name': 'Article Ratings',
      'url': '/kb/feedback/id/' + kbID,
      'uid': 'feedback'
    },
    {
      'icon': 'fa-star',
      'name': 'Article Favorites',
      'url': '/kb/favorites/id/' + kbID,
      'uid': 'favorites'
    },
    {
      'icon': 'fa-file-pdf',
      'name': 'PDF',
      'url': '/kb/pdf-settings/id/' + kbID,
      'uid': 'pdf-settings'
    },
    {
      'icon': 'fa-comment-alt',
      'name': 'Comments',
      'url': '/kb/comments/id/' + kbID,
      'uid': 'comments'
    },
    {
      'icon': 'fa-bell',
      'name': 'Subscriptions',
      'url': '/kb/subscriptions/id/' + kbID,
      'uid': 'subs'
    },
    {
      'icon': 'fa-check-to-slot',
      'name': 'Required Reading',
      'url': '/kb/required-reading/id/' + kbID,
      'uid': 'required-reading'
    },
    {
      'icon': 'fa-object-group',
      'name': 'Widget',
      'url': '/kb/widget/id/' + kbID,
      'uid': 'widget'
    }
  ];

  // make sure section is open current page is in this section
  let activeSectionItem = settingsLinks.find((link, i) => {
    if(link.uid == activeNav)
      openSection("settings");
  });

</script>
<CollapsibleSection expansionKey="settings">
  <label
    class="tw-mb-0 tw-text-sm tw-cursor-pointer"
    slot="label"
    for="settings"
    role="presentation"
    on:click={() => {
      toggleSection("settings");
    }}>SETTINGS</label
  >
  <div slot="open-content">
    {#each settingsLinks as link}
      <NavItem
        {link}
        {activeNav}
      />
    {/each}
  </div>
</CollapsibleSection>